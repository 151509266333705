.expert_bg{
    background-image: url(../../assets/img/newHome/expert_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.expert_text{
    color: #000;
    text-align: center;
    font-family: Suez One;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 115.5%;
    margin-top: 20px;
}