.cdr_banner{
    background-image: url(../../../assets/img/newHome/research_bg.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}
.research_help{
    background-image:url(../../../assets/img/newHome/best_res_help.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}