.disser_banner{
    background-image: url(../../../assets/img/newHome/dissersation_bg.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
}
.writing_sub_text{
    color: rgba(14, 14, 14, 0.81);
    text-align: justify;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.padding_top{
    padding-top: 100px;
}
.padding_top150{
    padding-top: 150px;
}
.heading_reason{
    font-size: 35px;
    font-weight: 500;
}
.writing_img{
    display: flex;
    justify-content: space-evenly;
    padding-top: 90px;
}