.border{
    border: 1px solid #4b5263;
}
.tite{
    background: rgba(16, 14, 146, 0.2) !important;
}

.fileleft{
    float: left;
}

.react-tel-input .form-control{
        height: 42px !important;
        width: 100% !important;

}

.react-tel-input .country-list {
    text-align: left !important;
}
.owl-carousel .owl-item img{
    /* width: 110px !important; */
}

.testileft{
    padding-left: 10% !important;
}
.owl-carousel .owl-item{
    position: relative !important;
    min-height: 0px !important;
    float: left !important;
}
    
.home_banner{
    background-image: url(../../assets/img/newHome/home_banner.webp);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 105px;
}
.header_font{
    font-family: Suez One;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 73.92px */
    text-transform: capitalize;
    
}
.header_sub_font{
    color: rgba(14, 14, 14, 0.81);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; 
    text-align: justify;
}
.get_started{
    border-radius: 30px;
    padding: 10px 20px;
    background: #FEC00F;
    cursor: pointer;
}
.get_started:hover{
    text-decoration: none !important;
}
.bg-black{
    background-color: black;
    padding: 50px;
}
.number{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}
.request_text{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.5px;
    margin-top: 25px;
}
.grades{
    color: #121212;
    text-align: center;
    font-family: Suez One;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 55.44px */
    text-transform: capitalize;
}
.experience{
    color: rgba(14, 14, 14, 0.81);
    text-align: center;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.grades_banner{
    background-image: url(../../assets/img/newHome/grades_banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.grade_block{
    text-decoration: none !important;
    margin: 35px;
    padding: 25px;
    text-decoration: none !important;
    border-radius: 10px;
    background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15), 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    width: 325px;
    height: 300px;
}
.grade_block:hover{
    text-decoration: none !important;
}
.provide_banner{
    background-image: url(../../assets/img/newHome/provide.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 105px;
}
.adjust{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.step_text{
    color: #121212;
    font-family: Suez One;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 36.96px */
    text-transform: capitalize;
}
.step_sub_text{
    color: rgba(14, 14, 14, 0.81);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.we_provide_subtext{
    color: rgba(14, 14, 14, 0.81);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}
.analytics_view{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
}
.process_sec{
    background-image: url(../../assets/img/newHome/process_banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 250px;
}
.black_bg{
    border-radius: 30px;
    background: linear-gradient(180deg, #272727 0%, #2D2D2D 80%);
    margin-top: 20px;
}
.form_heading{
    font-size: 30px;
    color: #000;
    margin-bottom: 25px;
    margin-top: 15px;
    text-align: center;
}
.field{
    border-radius: 30px !important;
    height: 50px !important;
}
.bg-yellow{
    background-color: #FEC00F;
    padding: 50px;
}
.btn_color{
    color: #fff !important;
    background-color: #000;
}
.test_image{
    border: 2px solid #000;
    margin: 20px;
    width: 70% !important;
}