.research_banner{
    background-image: url(../../../assets/img/newHome/research_bg.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
}
.research_block{
    border-radius: 10px;
    background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15), 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    padding: 50px;
    height: 330px;
    margin-top: 20px;
}
.block_sub_text{
    color: rgba(14, 14, 14, 0.81);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
    text-align: justify;
    margin-top: 25px;
}
.block_head_text{
    color: #121212;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 115.5%;
    margin-left: 20px;
}