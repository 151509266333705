
.css-rciaot{
    height: 104.6%;
}

.css-42igfv {
    margin-top: 50% !important;
    margin-left: 43% !important;
}

@media only screen and (max-width: 600px) {
    .css-rciaot {
        height: 103.3%;
    }
    .css-42igfv {
        margin-top: 169% !important;
        margin-left: 31% !important;
    }
  } 

.contact_sub_text{
    color: rgba(14, 14, 14, 0.81);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; 
    margin-right: 10%;
    margin-left: 10%;
  }
  .bg_black{
    border-radius: 30px;
    background: linear-gradient(180deg, #454545 0%, #000 100%);
    margin-left: -130px;
    padding-bottom: 145px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .contact_us{
    margin-top: 40px;
    margin-bottom: 40px;
    color: #FFF;
    text-align: center;
    font-family: Suez One;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 115.5%; /* 46.2px */
    text-transform: capitalize;
  }
  .text_field{
    color: rgba(0, 0, 0, 0.40);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    border-radius: 50px;
    margin-top: 20px;
    padding-left: 30px;
  }
  .contact_banner{
    background-image: url(../../assets/img/newHome/contact_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .text_area{
    height: 230px !important;
    border-radius: 30px;
    font-size: 24px;
    padding-left: 30px;
    margin-top: 20px;

  }
  .equation{
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }
  .equation_field{
        height: 52px;
        width: 86px;
        border-radius: 25px;
        margin-left: 12px;
        color: #000;
  }
  .css-42igfv{
    margin-top: 30% !important;
  }
  .contact_img{
    margin-left: 70px;
  }

  .stdropdown-container {
    border: none !important; /* Removes any border */
    box-shadow: none; /* Removes shadow, if any */
  }
  .react-tel-input .form-control{
    border-radius: 27px !important;
    height: 57px !important;
  }
  .react-tel-input .flag-dropdown{
    border-radius: 25px 0 0 28px !important
  }
  