.single-widget {
    border: 1px solid #eee;
    padding: 65px 30px;
    margin-bottom: 30px;
}

.search-widget i {
    color:  #0E0D8C;
}

.cardcolor{
    background: #e0e0e0;
    /* color: black; */
}

.active2 a {
    background-color: #ffc107;
    color: white !important;
    float: left;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 5px;
    margin-bottom: 30px;
    cursor: pointer;
}

.label {
    /* max-width: 16%; */
    float: left;
    text-align: center;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: #222222;
    padding: 5px;
    margin: 0.5%;
    margin-bottom: 30px;
    cursor: pointer;
}

.search-widget form.example input[type=text] {
    padding: 10px;
    font-size: 14px;
    border: none;
    float: left;
    width: 80%;
    background: #eee;
    margin-top: -17px
}

.search-widget form.example button {
    float: left;
    width: 20%;
    padding: 8px;
    background: #eee;
    color: white;
    font-size: 17px;
    border: none;
    cursor: pointer;
    margin-top: -17px
}
