.samplszise{
    padding: 30px;
}

@media only screen and (max-width: 600px) {
    .samplszise{
        padding: 24px
    }
  }
.sample_div{
    height: 500px !important;
    max-height: 500px !important;
    cursor: pointer;
}