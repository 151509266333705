.footer_contact{
    color: #121212;
    font-family: Suez One;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 115.5%; /* 41.58px */
    text-transform: capitalize;
}
.footer_sub_text{
    color: rgba(14, 14, 14, 0.81);

font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 36px;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle{
    background-color:#fff !important;
    border-color: #fff !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus{
    box-shadow: none !important;
}
.btn-outline-primary:hover{
    background-color: none !important;
}