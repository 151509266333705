.essay_banner{
    background-image: url(../../../assets/img/newHome/essay_bg.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 22px;
    margin-top: 25px;
}
.essay_text{
    color: #000;
    text-align: justify;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.field_text{
    color: rgba(14, 14, 14, 0.81);
    margin: 70px;
    text-align: justify;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}
.yellow_bg{
    background-image: url(../../../assets/img/newHome/yellow_bg.png);
}
.field_block_text{
    color: rgba(14, 14, 14, 0.81);
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}
.field_block_heading{
    color: rgba(14, 14, 14, 0.81);
    font-family: Suez One;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-transform: capitalize;
    text-align: center;
}
.fields_div{
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    height: 600px;
    max-height: 600px;
    overflow: hidden;
    margin: 10px;
}
.center_div{
    display: flex;
    justify-content: space-evenly;
}
.mobile_menu{
    display: flex;
  }